import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { Button, Icon } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { getNames, selectAuth } from '@/features/auth';
import { useGetProductQuery } from '@/features/payment';
import { useAppSelector } from '@/hooks';

import { usePremium } from '../..';
import amexIcon from '../../assets/amex.svg';
import discoverIcon from '../../assets/discover.svg';
import maestroIcon from '../../assets/maestro.svg';
import masterCardIcon from '../../assets/mastercard.svg';
import paypalIcon from '../../assets/paypal.svg';
import visaIcon from '../../assets/visa.svg';
import { calculatePriceDiffBetweenMonthlyAndYearly } from '../../utils/calculate-price-diff';
import styles from './ChoosePlan.module.scss';
import { CountDown } from './CountDown/CountDown';
import { LegalText } from './LegalText/LegalText';
import { PlanItem, type PlanItemAdornment } from './PlanItem/PlanItem';

interface Product {
  lookupKey: string;
  name: string;
  amount: number;
  price: string;
  pricePerDay: string;
  adornment: PlanItemAdornment | undefined;
}

export function ChoosePlan(): ReactNode {
  const { data: products } = useGetProductQuery({
    productId: import.meta.env.VITE_PADDLE_PRODUCT_ID,
  });
  const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);
  const auth = useAppSelector(selectAuth);
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const navigate = useNavigate();
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const createNewTransactionCalled = useRef(false);

  useEffect(() => {
    if (createNewTransactionCalled.current) {
      return;
    }

    createNewTransactionCalled.current = true;
    logBigQueryEvent('lnd_paywall');
    logEvent('lnd_paywall', {
      paywallType: 'in_app',
    });
  }, [logBigQueryEvent]);

  const plans = useMemo(() => {
    if (products === undefined) {
      return [];
    }

    const monthlyPlan = products.find(
      (product) =>
        product.billingCycle.interval === 'month' &&
        product.billingCycle.frequency === 1 &&
        product.status === 'active',
    );

    const thirdMonthsPlan = products.find(
      (product) =>
        product.billingCycle.interval === 'month' &&
        product.billingCycle.frequency === 3 &&
        product.status === 'active',
    );

    const yearlyPlan = products.find(
      (product) =>
        product.billingCycle.interval === 'year' &&
        product.billingCycle.frequency === 1 &&
        product.status === 'active',
    );

    if (
      monthlyPlan === undefined ||
      yearlyPlan === undefined ||
      thirdMonthsPlan === undefined
    ) {
      return [];
    }

    const priceFormatter = Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    const priceFormatterFractionless = Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const adornments: Record<string, PlanItemAdornment | undefined> = {
      [monthlyPlan.id]: { type: 'none', discount: '$1.80' },
      [thirdMonthsPlan.id]: { type: 'bestseller', discount: '$1.20' },
      [yearlyPlan.id]: {
        type: 'save',
        value: priceFormatterFractionless.format(
          calculatePriceDiffBetweenMonthlyAndYearly({
            monthlyPrice: parseFloat(monthlyPlan.unitPrice.amount),
            yearlyPrice: parseFloat(yearlyPlan.unitPrice.amount),
          }) / 100, // Convert sub-unit for that currency to unit amount.
        ),
      },
    };

    const planDetails: Record<string, string> = {
      [monthlyPlan.id]: '1 Month',
      [thirdMonthsPlan.id]: '3 Months',
      [yearlyPlan.id]: '12 Months',
    };

    return products
      .filter((product) => product.status === 'active')
      .map((product) => {
        const plan = product.id;
        const price = parseFloat(product.unitPrice.amount) / 100;
        const pricePerDay =
          product.billingCycle.interval === 'month'
            ? price / (30 * product.billingCycle.frequency)
            : price / (365 * product.billingCycle.frequency);

        const finalProduct: Product = {
          lookupKey: plan,
          name: planDetails[plan],
          amount: parseFloat(product.unitPrice.amount),
          price: priceFormatter.format(price),
          pricePerDay: priceFormatter.format(pricePerDay),
          adornment: adornments[plan],
        };

        return finalProduct;
      })
      .sort((a, b) => a.amount - b.amount);
  }, [products]);

  const handleContinue = (): void => {
    if (auth.user === null || selectedPlan?.lookupKey === undefined) {
      return;
    }

    const eventData: Record<string, string | null> = {
      email: auth.user.email,
      external_id: auth.user.uid,
    };

    if (auth.user.displayName !== null) {
      const userNames = getNames(auth.user.displayName);

      if (userNames === null) {
        return;
      }

      eventData.first_name = userNames.firstName;
      eventData.last_name = userNames.lastName;
    }

    if ('dataLayer' in window) {
      window.dataLayer.push({
        ...eventData,
        items: [
          {
            item_id: selectedPlan.lookupKey,
            item_name: 'Premium',
            price: selectedPlan.amount,
            quantity: 1,
            item_category: 'subscription',
            item_variant: 'premium',
          },
        ],
      });
    }

    logEvent('purchase_button_click');
    logBigQueryEvent('purchase_button_click');

    const searchParams = new URLSearchParams();
    searchParams.set('plan', selectedPlan?.lookupKey);
    navigate(`/choose-plan/checkout?${searchParams.toString()}`);
  };

  if (premiumLoading) {
    return null;
  } else if (isPremium === true) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.container}>
      <div className={styles['choose-plan']}>
        <div className={styles['choose-plan__title']}>Choose Your Plan</div>

        <div className={styles['choose-plan__countdown']}>
          <strong>33%</strong> DISCOUNT RESERVED FOR{' '}
          <CountDown className={styles.counter} />
          <Icon icon={faBolt} />
        </div>

        <fieldset className={styles.plans__fieldset}>
          {plans.map((product) => (
            <PlanItem
              key={product.lookupKey}
              lookupKey={product.lookupKey}
              name={product.name}
              undiscountedPrice={product.adornment?.discount}
              price={product.price}
              pricePerDay={product.pricePerDay}
              adornment={product.adornment}
              checked={selectedPlan?.lookupKey === product.lookupKey}
              onSelectPlan={() => {
                setSelectedPlan(product);
              }}
            />
          ))}
        </fieldset>

        <Button
          size="large"
          variant="primary"
          type="submit"
          disabled={selectedPlan === null}
          onClick={handleContinue}
        >
          Continue
        </Button>

        <LegalText />

        <span className={styles['pay-safe-secure']}>
          <Icon icon={faShieldCheck} /> Pay safe & secure
        </span>

        <div className={styles['choose-plan__payment-methods']}>
          <img src={visaIcon} alt="visa" />
          <img src={masterCardIcon} alt="master card" />
          <img src={discoverIcon} alt="discover" />
          <img src={paypalIcon} alt="paypal" />
          <img src={amexIcon} alt="amex" />
          <img src={maestroIcon} alt="maestro" />
        </div>
      </div>
    </div>
  );
}
