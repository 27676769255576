import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { type ReactElement } from 'react';
import { PhotoProvider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { v4 as uuidv4 } from 'uuid';

import { logEvent } from '@/features/analytics';
import { storage } from '@/providers/firebase';

import { type MessageImageType } from '../../../../../../types/message';
import { downloadImage } from '../../../../../../utils/download-image';
import { ImageItem } from './ImageItem/ImageItem';
import styles from './ImageList.module.scss';
import { PhotoViewToolbar } from './PhotoViewToolbar/PhotoViewToolbar';

export function ImageList({
  images,
  onClickRetry,
}: {
  images: MessageImageType[];
  onClickRetry: ({
    image,
    imageIndex,
  }: {
    image: MessageImageType;
    imageIndex: number;
  }) => void;
}): ReactElement {
  const handleDownloadImage = async ({
    imagePath,
  }: {
    imagePath: string;
  }): Promise<void> => {
    const ref = storageRef(storage, imagePath);
    const imageUrl = await getDownloadURL(ref);

    const processId =
      // Extract processId from imagePath.
      /\/(?<processId>\d+)\//.exec(imagePath)?.groups?.processId ?? uuidv4();

    await downloadImage({
      url: imageUrl,
      fileName: processId,
    });
  };

  return (
    <PhotoProvider
      className={styles['photo-view-mask-blur']}
      maskClosable={false}
      maskOpacity={0.4}
      loop
      toolbarRender={({ index }) => (
        <PhotoViewToolbar
          onClickDownloadImage={() => {
            logEvent('btn_download_image');
            void (async () => {
              const image = images[index];
              await handleDownloadImage({ imagePath: image.url });
            })();
          }}
        />
      )}
    >
      <div className={styles['image-list']}>
        {images.map((image, index) => (
          <ImageItem
            prompt={image.prompt}
            url={image.url}
            state={image.state}
            key={index}
            onClickRetry={() => {
              onClickRetry({ image, imageIndex: index });
            }}
            onClickDownload={() => {
              logEvent('btn_download_image');
              void handleDownloadImage({ imagePath: image.url });
            }}
          />
        ))}
      </div>
    </PhotoProvider>
  );
}
